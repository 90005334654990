import { PaymentRegistryService } from '@/modules/payment-registry/payment-registry-service';
import Errors from '@/shared/error/errors';

// action types
export const DO_FETCH = 'doFetch';
export const GENERATE_REPORT = 'generateReport';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const UPDATE_RECORD = 'updateRecord';
export const SET_SORT = 'setSort';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
    storeSort: {sortBy: '', sortDesc: false},
    filters: {}
};

const getters = {
    rows: (state) => state.rows || [],
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    sorting: (state) => state.storeSort,
    reportFilters: (state) => state.filters
};

const actions = {
    [DO_FETCH](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'paymentRegistry/get', { root: true });

            payload.internal = false; //fetch internal documents.

            const filters = { params: { ...payload, pagination: true, sort: '-created_date' } };
            PaymentRegistryService.list(filters)
                .then((data) => {
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data?.pagination?.total,
                    });
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'paymentRegistry/get', { root: true });
                });
        });
    },
    [GENERATE_REPORT](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'paymentRegistry/generateReport', { root: true });

            PaymentRegistryService.generateReport(payload)
                .then((data) => {
                    const blob = new Blob([data.data], {type: 'text/csv'})
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'raport-taxe.csv';
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'paymentRegistry/generateReport', { root: true });
                });
        });
    }
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
        state.filters[key] = value;
    },
    [UPDATE_RECORD](state, record) {
        const recordIndex = state.rows.findIndex((el) => el.id === record.id);
        Object.assign(state.rows[recordIndex], { ...record });
    },
    [SET_SORT](state, value) {
        state.storeSort = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
